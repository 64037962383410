<template>
  <div class="auth-wrapper"></div>
</template>

<script>
import Vue from 'vue'
import { Toast, Notify } from 'vant'
import { getBindWechat } from '@/api/my'
import { is_weixn } from '@/utils/brower'
import { prodUrl } from '@/config/env'
Vue.use(Toast, Notify)
export default {
  name: 'Auth',
  mounted() {
    if (is_weixn()) {
      this.bindWechat()
    } else {
      this.$router.push('/')
    }
  },
  methods: {
    /**
     * 绑定微信
     */
    bindWechat() {
      const code = this.getQueryString('code')
      if (!code) {
        const redirect_uri = window.location.href
        console.log(window.location.href)
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd879b4d063c99cfa&redirect_uri=${encodeURIComponent(
          redirect_uri
        )}&response_type=code&scope=snsapi_base&state=123&connect_redirect=1#wechat_redirect`
      } else {
        this.getBindWechat(code)
      }
    },
    /**
     * 获取url参数
     */
    getQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    /**
     * 绑定微信
     */
    getBindWechat(code) {
      getBindWechat({
        code,
      })
        .then((res) => {
          if (res.retCode === 1) {
            Notify({ type: 'success', message: res.retMsg })
          } else {
            Notify({ type: 'danger', message: res.retMsg })
          }
          setTimeout(() => {
            window.location.href = prodUrl
          }, 1000)
        })
        .catch((err) => {
          Notify({ type: 'danger', message: err })
        })
    },
  },
}
</script>

<style scoped lang="scss">
</style>
